// translation:no-need
import * as Sentry from '@sentry/react';
import log from 'loglevel';
import { createRoot } from 'react-dom/client';
import { SITE_IS_DOWN, RELEASE_NAME } from './constants/main';
import { URL_REGEX } from '@/utils/regex';
import { isNative } from '@/utils/crossPlatform';
import { persistLocalStorageHandler } from '@/native/General/persistLocalStorageHandler';
import { handleBackButton } from '@/native/General/nativeBackButtonHandler';
import { AppLaunchStatusUtil } from '@/native/AppLaunchStatus/AppLaunchStatus';
import { PERMISSIONS_DEBUG_KEY } from '@/context/Authorization/constants';
import { DYNAMIC_RENDER_DEBUG_KEY } from '@/scenes/People/DynamicRender/DynamicRender.constants';
import { lazy, Suspense } from 'react';

// Polyfills to support .replaceAll and .at
import 'core-js/es/string/replace-all';
import 'core-js/es/array/at';
import 'core-js/es/string/at';

// polyfill to support structuredClone
import 'core-js/actual/structured-clone';

// polyfill to support toSorted and toSortedArray
import 'core-js/actual/array/to-sorted';
import 'core-js/actual/array/to-reversed';

// Polyfill for CSSStyleSheet
import 'construct-style-sheets-polyfill';

// CON-6828 Override Yup email validation to match BE validation
import './utils/emailValidation';
import ErrorBoundary from '@/components/ErrorBoundary/ErrorBoundary';

const LoadingScreen = lazy(() => import('./components/LoadingScreen/LoadingScreen'));

window.log = log;
log.setDefaultLevel((localStorage.getItem('LOG_LEVEL') || 'warn').toLowerCase());

const changeStage = (newStage) => {
  localStorage.setItem('VITE_STAGE', newStage);
  location.reload();
};

window._dev = () => {
  changeStage('development');
};

window._local = () => {
  changeStage('local');
};

window._giger = (gigerEnv) => {
  if (!gigerEnv) {
    console.warn("You didn't provide a giger env");
    return;
  }
  const isURL = gigerEnv.match(URL_REGEX);
  changeStage(isURL ? gigerEnv : `https://api-${gigerEnv}.giger.training`);
};

const PERMISSIONS_DEBUG_OPTIONS = ['orgSets', 'teamSets', 'allSets', 'noSets', 'permissionSets'];

window._permissionsDebug = (...args) => {
  if (args.length === 0 && localStorage.getItem(PERMISSIONS_DEBUG_KEY)) localStorage.removeItem(PERMISSIONS_DEBUG_KEY);
  else localStorage.setItem(PERMISSIONS_DEBUG_KEY, JSON.stringify(args.length === 0 ? ['noSets'] : args));
  // eslint-disable-next-line no-console
  console.log('Permissions debug mode is now', localStorage.getItem(PERMISSIONS_DEBUG_KEY) ? 'ON' : 'OFF');
  // eslint-disable-next-line no-console
  console.log(`Possible options: `, PERMISSIONS_DEBUG_OPTIONS);
  location.reload();
};

window._dynamicRenderDebug = () => {
  if (localStorage.getItem(DYNAMIC_RENDER_DEBUG_KEY)) localStorage.removeItem(DYNAMIC_RENDER_DEBUG_KEY);
  else localStorage.setItem(DYNAMIC_RENDER_DEBUG_KEY, 'true');
  // eslint-disable-next-line no-console
  console.log('Dynamic render debug mode is now ', localStorage.getItem(DYNAMIC_RENDER_DEBUG_KEY) ? 'ON' : 'OFF');
  location.reload();
};

window.DEEL_VERSION = import.meta.env.VITE_CI_COMMIT_SHA ? RELEASE_NAME : 'unknown';

window.forceCrash = () => {
  setTimeout(() => {
    throw new Error('Simulated Crash');
  }, 0);
};

const IGNORED_ERRORS = ['amplitude is not defined', `Can't find variable: amplitude`];

Sentry.init({
  release: RELEASE_NAME,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [Sentry.browserTracingIntegration({ enableInp: true })],
  tracesSampleRate: 0.25,
  ignoreErrors: ['Non-Error exception captured with keys'],
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  beforeSend: (event, hint) => {
    const error = hint.originalException;
    const isIgnoredError = IGNORED_ERRORS.some((item) => error?.message?.includes(item));
    if (error?.message && isIgnoredError) return null;

    return event;
  },
});

const Loader = () => {
  return (
    <Suspense fallback={null}>
      <LoadingScreen show={true} />
    </Suspense>
  );
};

const renderErrorPage = async (err, root) => {
  root.render(
    <ErrorBoundary fallback={null}>
      <Loader />
    </ErrorBoundary>
  );
  console.error('Sorry. There was a problem loading domain config.', err);
};

const container = document.getElementById('root');
const root = createRoot(container);

async function loadTranslations() {
  const currentLanguage = localStorage.getItem('language') || 'en';
  document.querySelector('html').lang = currentLanguage;

  if (currentLanguage === 'en') {
    return;
  }

  try {
    const version = window.DEEL_VERSION;
    const versionStr = version !== 'unknown' ? `?v=${version}` : '';
    const response = await fetch(`/translations/${currentLanguage}.json${versionStr}`, {
      cache: 'force-cache',
    });
    if (!response.ok) {
      throw new Error('Failed to load translation file');
    }
    const data = await response.json();
    window.translations = data;
  } catch (error) {
    console.error('Error loading translations:', error);
  }
}

const renderApp = async () => {
  await loadTranslations();
  await import('./i18n');
  const initApp = (await import('./initApp')).default;
  initApp(root);
};

const start = async () => {
  if (SITE_IS_DOWN) {
    const Maintenance = (await import('./scenes/Maintenance/Maintenance')).default;
    root.render(<Maintenance />);
  } else {
    (async () => {
      try {
        await renderApp(root);
      } catch (err) {
        renderErrorPage(err, root);
      }
    })();
  }
};

if (!isNative()) {
  // For web - start
  start();
} else {
  AppLaunchStatusUtil.registerKeys();
  if (localStorage.getItem('LS_INTACT') !== 'true') {
    // For native app - if needed - first load local storage from persistent storage.
    // Local storage is fetched when code is imported, so reload is needed to apply changes
    persistLocalStorageHandler().then(() => {
      localStorage.setItem('LS_INTACT', 'true');
      location.reload();
    });
  } else {
    // For native app - start
    handleBackButton();
    start();
  }
}
