import { Capacitor } from '@capacitor/core';
import { UserAgent } from '@adeprez/capacitor-user-agent';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/react';
import type { AxiosInstance, AxiosRequestHeaders } from 'axios';
import { isISO88591 } from './string';
import i18n from 'i18next';

type NativeHeaders = {
  'Accept-Language': string;
  'x-device-os': string;
  'x-device-id': string;
  'x-device-model': string;
};

export const isNative = () => {
  return Capacitor.isNativePlatform();
};

export const isNativeIos = () => {
  return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'ios';
};

export const isNativeAndroid = () => {
  return Capacitor.isNativePlatform() && Capacitor.getPlatform() === 'android';
};

export const getUserAgent = async () => {
  const storedUserAgent = localStorage.getItem('native-user-agent');
  if (storedUserAgent) {
    return storedUserAgent;
  }
  const result = await UserAgent.get();
  localStorage.setItem('native-user-agent', result.userAgent);
  return result.userAgent;
};

//Returns language code of the device
//This value is independent of app language, which can be switched by user
//Example format: 'en' / 'de' / 'es' etc...
let cachedDeviceLanguageCode: string | null = null;
export const getDeviceLanguageCode = async () => {
  if (!isNative()) {
    return '';
  }
  if (cachedDeviceLanguageCode) {
    return cachedDeviceLanguageCode;
  } else {
    try {
      const { value: languageCode } = await Device.getLanguageCode();
      cachedDeviceLanguageCode = languageCode;
      return languageCode;
    } catch {
      return 'en';
    }
  }
};

const getFallbackDeviceId = () => {
  const fallbackDeviceId = localStorage.getItem('native-fallback-device-id');
  if (fallbackDeviceId) {
    return fallbackDeviceId;
  }
  const newDeviceId = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
  localStorage.setItem('native-fallback-device-id', newDeviceId);
  return newDeviceId;
};

export const getDeviceId = async () => {
  if (!isNative()) {
    return '';
  }
  try {
    const { identifier: deviceId } = await Device.getId();
    return deviceId;
  } catch (error) {
    Sentry.addBreadcrumb({
      message: 'Failed to get device ID',
    });
    Sentry.captureException(error);
    return getFallbackDeviceId();
  }
};

export const crossPlatformProps = {
  isNative: isNative(),
  isNativeAndroid: isNativeAndroid(),
  isNativeIos: isNativeIos(),
};

export const getNativeHeaders = async (
  header?: AxiosInstance['defaults']['headers'] | AxiosRequestHeaders
): Promise<NativeHeaders | {}> => {
  if (!isNative()) return {};
  try {
    const deviceInfo = await Device.getInfo();
    const deviceID = await Device.getId();
    const deviceLanguageCode = await getDeviceLanguageCode();
    const deviceModel =
      deviceInfo.model && isISO88591(deviceInfo.model)
        ? deviceInfo.model
        : isNativeIos()
        ? i18n.t('common.misc.native.genericIosDeviceModel')
        : i18n.t('common.misc.native.genericAndroidDeviceModel');

    const nativeHeaders = {
      'Accept-Language': deviceLanguageCode,
      'x-device-os': deviceInfo.platform.toUpperCase(),
      'x-device-id': deviceID.identifier,
      'x-device-model': deviceModel,
    };

    if (header) {
      Object.assign(header, nativeHeaders);
    }

    return nativeHeaders;
  } catch (error) {
    Sentry.captureException(error);
    return {};
  }
};

export const isAndroidWeb = () => {
  return !Capacitor.isNativePlatform() && /android/i.test(navigator.userAgent);
};

export const isIOSWeb = () => {
  return !Capacitor.isNativePlatform() && /iPhone/.test(navigator.userAgent);
};
